import React from 'react'

const Banner = (props) => (
    <section id="banner" className="major">
        <div className="inner">
            <header>
                <h1>COVID-19 Observatory</h1>
                <h2>An MIT collaboration with the World Bank</h2>
            </header>
            <div className="content">
                <p>Global tracking of the real-time evolution of the COVID-19 pandemic</p>
                <ul className="actions">
                    {/* <li><a href="#one" className="button next scrolly">Learn more</a></li> */}
                </ul>
            </div>
        </div>
    </section>
)

export default Banner
