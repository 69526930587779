import React from 'react'
import Helmet from 'react-helmet'
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from '../components/layout'
import Banner from '../components/Banner'

import dhaval from '../assets/images/people/dhaval.jpg'
import travizano from '../assets/images/people/travizano.jpg'
import sam from '../assets/images/people/sam.jpg'
import sandy from '../assets/images/people/sandy.jpg'
import derekWhite from '../assets/images/people/Derek_White_lo_11.jpg'
import crowley from '../assets/images/people/dennis_crowley.jpg'
import Birkhead from '../assets/images/people/Mark_Birkhead.jpg'
import ricardo from '../assets/images/people/ricardo.jpg'
import colleen from '../assets/images/people/ColleenBerube.jpg'
import vespignani from '../assets/images/people/vespignani.jpg'
import chinazzi from '../assets/images/people/chinazzi.jpg'
import tobyScammell from '../assets/images/people/Toby_Scammell.jpg'

import indexStyles from './index.module.scss'
import previewImg from '../assets/images/preview.jpg'

const pageTitle = 'COVID-19 Observatory'

const HomeIndex = () => {
    const data = useStaticQuery(graphql`
        query {
            maskMainFigure: file(relativePath: { eq: "research/mask.png" }) {
                childImageSharp {
                    fluid(maxWidth: 300) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    return (
        <Layout>
            <Helmet
                title={pageTitle}
                meta={[
                    {
                        name: 'description',
                        content:
                            'Aggregate data to fuel the global response to the COVID-19 crisis',
                    },
                    {
                        name: 'keywords',
                        content:
                            'data, covid, coronavirus, analytics, dashboard',
                    },
                    { property: 'og:site_name', content: pageTitle },
                    { property: 'og:title', content: pageTitle },
                    {
                        property: 'og:image',
                        content: `https://c19observatory.mit.edu${previewImg}`,
                    },
                    { property: 'og:image:type', content: 'image/jpeg' },
                    { property: 'og:image:width', content: 1116 },
                    { property: 'og:image:height', content: 829 },
                    { name: 'twitter:card', content: 'summary_large_image' },
                ]}
            />

            <Banner />

            <div id="main">
                <section id="two" className={indexStyles.two}>
                    <div className="inner">
                        <header className="major">
                            <h2>Overview</h2>
                        </header>
                        <p>
                            The COVID-19 pandemic is having a tremendous
                            socioeconomic impact around the world, the scale of
                            which is still hard to assess.
                        </p>
                        <p>
                            We have gathered a unique team of computer scientists, social
                            scientists, and business leaders to gather massive datasets from the
                            private sector in order to provide live and
                            actionable insights for other researchers,
                            policymakers, and the general public, while
                            respecting privacy.
                        </p>
                        <p>
                            Our objective will be to provide datasets quickly to researchers to allow for a rapid assessment
                            of the impact of policies aimed at
                            mitigating the spread of the COVID-19 pandemic, and
                            to quantify the extent to which changes in mobility,
                            social interactions, and purchasing patterns relate
                            to health and economic outcomes at a very high
                            resolution.
                        </p>
                        <p>
                            The goal of this collaboration will be to provide datasets leading to research for policymakers to implement
                            policy actions in a timely, efficient, and targeted way.
                        </p>

                    </div>
                </section>

                <section id="three" className={indexStyles.two}>
                    <div className="inner">
                        <header className="major">
                            <h2>Privacy</h2>
                        </header>
                        <p>
                            Our team is highly experienced in developing and
                            deploying numerous privacy-preserving
                            {' '}
                            <a href="https://science.sciencemag.org/content/347/6221/536">
                                research
                            </a>
                            ,
                            {' '}
                            <a href="https://trust.mit.edu/research">
                                pipelines and algorithms
                            </a>
                            {' '}
                            such as
                            {' '}
                            <a href="https://www.opalproject.org/about-opal">
                                OPAL: Open Algorithms
                            </a>
                            {' '}
                            and
                            {' '}
                            <a href="https://arxiv.org/pdf/1506.03471.pdf">
                                Secure Multi-Party Computation using Blockchain
                            </a>
                            .
                        </p>

                        <p>
                            We are deeply committed to preserving the privacy
                            and security of the data we will be using. Our
                            principles are:
                        </p>
                        <ul>
                            <li>
                                We will not merge different data sources at the
                                individual level, but will instead calculate
                                aggregate merged statistics at the census block,
                                county level and similarly large administrative
                                units.
                            </li>
                            <li>
                                We will only use anonymized data with no
                                personal identifiers.
                            </li>
                            <li>
                                We will use data at the highest level of time,
                                space and group aggregation that still allows us
                                to gather useful insights.
                            </li>
                            <li>
                                Working with public health practitioners and
                                policy makers, we will only run analysis that
                                has a net positive benefit to society.
                            </li>
                            <li>
                                The use of data will be in compliance with
                                existing laws and ethical standards.
                            </li>
                            <li>
                                We will elect not to receive data from our data
                                partners when possible, but instead elect to run
                                our models on our data partner&rsquo;s premises and we
                                will only transfer high-level statistics outside
                                of their data clouds.
                            </li>
                            <li>
                                We will use state-of-the art privacy and infosec
                                techniques and protocols.
                            </li>
                            <li>
                                We will dedicate a significant portion of our
                                effort into researching techniques that allow
                                for the use of sensitive data while maintaining
                                privacy.
                            </li>
                        </ul>

                        <p />
                    </div>
                </section>

                <section id="one" className={indexStyles.logolist}>
                    <header>
                        <h1 className="align-center">Core Research Team</h1>
                    </header>
                    <div className="grid-wrapper">
                        <div className="col-6">
                            <h3>
                                <a href="http://www.dval.me">Dhaval Adjodah</a>
                            </h3>
                            <h4>
                                Research Scientist, MIT + Consultant with the
                                World Bank
                            </h4>
                            <p>
                                <span className="image left">
                                    <img src={dhaval} alt="Dhaval Adjodah" />
                                </span>
                                <a href="http://www.dval.me">Dhaval Adjodah</a>
                                {' '}
                                is a computer scientist and policy researcher
                                pushing the limits of modern machine learning
                                while maximizing their social good. Currently,
                                he is a research scientist at the
                                {' '}
                                <a href="https://media.mit.edu">
                                    MIT Media Lab
                                </a>
                                {' '}
                                where he develops new algorithms and theory
                                using insights from causal inference, social
                                science, and cognitive science. He is also a
                                consultant to the
                                {' '}
                                <a href="https://www.worldbank.org/en/research/dime/data-and-analytics">
                                    World Bank
                                </a>
                                , where he helps to buildg new machine learning
                                pipelines to track and implement policy
                                decisions at the global level. He is a member of
                                the organizing committee of the
                                {' '}
                                <a href="https://aiforsocialgood.github.io/neurips2019/organizers.htm">
                                    AI for Social Good
                                </a>
                                {' '}
                                conference workshop series, and during his PhD
                                at the MIT Media Lab, he was a visiting
                                researcher in Prof. Yoshua Bengio&rsquo;s group at
                                {' '}
                                <a href="https://mila.quebec/en/person/bengio-yoshua/">
                                    MILA
                                </a>
                                , a member of the
                                {' '}
                                <a href="https://cyber.harvard.edu/node/100114">
                                    Harvard Berkman Assembly
                                </a>
                                {' '}
                                on Ethics and Governance in Artiﬁcial
                                Intelligence, and a fellow at the
                                {' '}
                                <a href="https://thecenter.mit.edu/home/fellows-the-center/">
                                    Dalai Lama Center For Ethics And
                                    Transformative Values
                                </a>
                                .
                            </p>
                        </div>
                        <div className="col-6">
                            <h3>
                                <a href="http://www.samuelfraiberger.com/">
                                    Sam Fraiberger
                                </a>
                            </h3>
                            <h4>Data Scientist, World Bank</h4>
                            <p>
                                <span className="image left">
                                    <img src={sam} alt="Sam Fraiberger" />
                                </span>
                                Sam Fraiberger is a computational social
                                scientist fascinated by how the digitization of
                                our lives allows us to study social processes at
                                an unprecedented scale, using massive datasets
                                and methods borrowed from computer science and
                                the social sciences. He is also a firm believer
                                in the power of data and technology to solve
                                societal challenges. He is currently part of the
                                Data Analytics and Tools team at the
                                {' '}
                                <a href="http://www.worldbank.org/">
                                    World Bank
                                </a>
                                , where he focuses on extracting insights from
                                very large datasets to tackle economic
                                development issues. He is also a Visiting
                                Researcher at
                                {' '}
                                <a href="https://nyunetworks.github.io/people.html">
                                    NYU Computer Science
                                </a>
                                , an
                                {' '}
                                <a href="https://connection.mit.edu/dr-sam-fraiberger">
                                    MIT Connection Science Fellow
                                </a>
                                , and a Senior Research Affiliate at
                                {' '}
                                <a href="http://datapopalliance.org/bio/sam-fraiberger/">
                                    Data-Pop Alliance
                                </a>
                                . He has published his research in prestigious
                                academic journals such as
                                {' '}
                                <a href="https://science.sciencemag.org/content/362/6416/825">
                                    Science
                                </a>
                                {' '}
                                and presented it at
                                {' '}
                                <a href="http://bit.ly/success-in-art">TEDx</a>
                                .
                                He holds a Msc from Harvard University and a PhD
                                from New-York University.
                            </p>
                        </div>
                        <div className="col-6">
                            <h3>
                                <a href="https://www.media.mit.edu/people/sandy/overview/">
                                    Alex &lsquo;Sandy&rsquo; Pentland
                                </a>
                            </h3>
                            <h4>
                                Professor of Media Arts and Sciences, Media Lab
                                Entrepreneurship Program Director
                            </h4>
                            <p>
                                <span className="image left">
                                    <img
                                        src={sandy}
                                        alt="Alex (Sandy) Pentland"
                                    />
                                </span>
                                Professor
                                {' '}
                                <a href="https://www.media.mit.edu/people/sandy/overview/">
                                    Alex &lsquo;Sandy&rsquo; Pentland
                                </a>
                                {' '}
                                directs
                                {' '}
                                <a href="http://connection.mit.edu/">
                                    MIT Connection Science
                                </a>
                                , an MIT-wide initiative, and previously helped
                                create and direct the
                                {' '}
                                <a href="https://www.media.mit.edu/">
                                    MIT Media Lab
                                </a>
                                {' '}
                                and the
                                {' '}
                                <a href="http://www.medialabasia.in/">
                                    Media Lab Asia
                                </a>
                                {' '}
                                in India. He is one of the
                                {' '}
                                <a href="https://scholar.google.com/citations?user=P4nfoKYAAAAJ&hl=en">
                                    most-cited
                                </a>
                                {' '}
                                computational scientists in the world, and
                                Forbes recently declared him one of the
                                {' '}
                                <a href="http://www.forbes.com/pictures/lmm45emkh/6-alex-sandy-pentland-professor-mit/">
                                    &ldquo;7 most powerful data scientists in the
                                    world&rdquo;
                                </a>
                                {' '}
                                along with Google founders and the Chief
                                Technical Officer of the United States. He is on
                                the Board of the UN Foundations&rsquo; Global
                                Partnership for Sustainable Development Data,
                                co-led the World Economic Forum
                                {' '}
                                <a href="https://hd.media.mit.edu/wef_globalit.pdf">
                                    discussion in Davos
                                </a>
                                {' '}
                                that led to the EU privacy regulation GDPR, and
                                was central in forging the transparency and
                                accountability mechanisms in the
                                {' '}
                                <a href="http://www.undatarevolution.org/wp-content/uploads/2014/11/A-World-That-Counts.pdf">
                                    UN&rsquo;s Sustainable Development Goals
                                </a>
                                . He has received numerous awards and prizes
                                such as the
                                {' '}
                                <a href="https://hbr.org/2013/04/the-2012-mckinsey-award-winners/ar/1">
                                    McKinsey Award
                                </a>
                                {' '}
                                from Harvard Business Review,
                                {' '}
                                <a href="http://archive.darpa.mil/networkchallenge/">
                                    the 40th Anniversary of the Internet
                                </a>
                                {' '}
                                from DARPA, and the
                                <a href="http://patientprivacyrights.org/2015-health-privacy-summit-agenda/">
                                    Brandeis Award
                                </a>
                                {' '}
                                for work in privacy. He is a member of advisory
                                boards for the UN Secretary General and the UN
                                Foundation, and previously for the American Bar
                                Association, Google, AT&T, and Nissan. He is a
                                serial entrepreneur who has co-founded more than
                                a dozen companies including social enterprises
                                such as the
                                {' '}
                                {' '}
                                <a href="http://www.datapopalliance.org/">
                                    Harvard-ODI-MIT DataPop Alliance
                                </a>
                                . He is a member of the
                                {' '}
                                <a href="http://www.nae.edu/107915.aspx">
                                    U.S. National Academy of Engineering
                                </a>
                                {' '}
                                and leader within the
                                {' '}
                                <a href="http://www.weforum.org/contributors/alex-pentland">
                                    World Economic Forum
                                </a>
                                .
                            </p>
                        </div>
                        <div className="col-6">
                            <h3>Mat Travizano</h3>
                            <h4>Founder CEO, GranData</h4>
                            <p>
                                <span className="image left">
                                    <img src={travizano} alt="Mat Travizano" />
                                </span>
                                Mat&rsquo;s passion is understanding people with data.
                                He pursues his passion by building technology
                                companies, conducting scientific research, and
                                engaging in public conversations with a radical
                                openness philosophy. Mat is the founder and CEO
                                of
                                {' '}
                                <a href="https://www.grandata.com/">Grandata</a>
                                , a San Francisco-based privacy-preserving data
                                analytics company. Grandata&rsquo;s partners include
                                some of the world&rsquo;s largest mobile carriers,
                                banks, and internet companies. Mat is also the
                                founder of
                                {' '}
                                <a href="https://wibson.org/">Wibson</a>
                                , a
                                company on a mission to empower citizens to
                                become data owners. Previously, Mat co-founded
                                Binaria Group, a social analytics company; and
                                Underground Security Systems Research, an
                                information security company. He has published
                                his research in prestigious academic journals
                                such as
                                {' '}
                                <a href="https://www.nature.com/search?author=%22Mat%C3%ADas+Travizano%22">
                                    Nature Comms
                                </a>
                                {' '}
                                two years in a row, and collaborated with the
                                Bill and Melinda Gates Foundation on the use of
                                alternative data to foster financial inclusion.
                                Mat speaks regularly in scientific and industry
                                conferences and is often featured in media such
                                as Wired, Forbes, or El Pais.
                            </p>
                        </div>
                    </div>
                </section>

                <section id="one" className={indexStyles.logolist}>
                    <header>
                        <h1 className="align-center">Research Collaborators</h1>
                    </header>
                    <div className="grid-wrapper">
                        <div className="col-6">
                            <h3>
                                <a href="https://cos.northeastern.edu/people/alessandro-vespignani/">
                                    Alex Vespignani
                                </a>
                            </h3>
                            <h4>
                                Director of MOBS Lab and Sternberg Family
                                Distinguished Professor, Northeastern University
                            </h4>
                            <p>
                                <span className="image left">
                                    <img
                                        src={vespignani}
                                        alt="Alex Vespignani"
                                    />
                                </span>
                                <a href="https://cos.northeastern.edu/people/alessandro-vespignani/">
                                    Alessandro Vespignani
                                </a>
                                {' '}
                                is the Director of the
                                {' '}
                                <a href="https://www.networkscienceinstitute.org">
                                    Network Science Institute
                                </a>
                                {' '}
                                and of the
                                {' '}
                                <a href="https://www.networkscienceinstitute.org">
                                    Modeling of Biological + Socio-technical
                                    Systems (MOBS) Lab
                                </a>
                                , and the Sternberg Family Distinguished
                                University Professor with interdisciplinary
                                appointments in the College of Computer and
                                Information Science, College of Science and the
                                Bouvé College of Health Sciences. His research
                                interests include complex systems and networks,
                                and the data-driven computational modeling of
                                epidemics. His research activity is focused on
                                the study of “techno-social” systems, where
                                infrastructures composed of different
                                technological layers are interoperating within
                                the social component that drives their use and
                                development. His group is one of the ones
                                {' '}
                                <a href="https://news.northeastern.edu/2020/04/01/northeastern-models-are-helping-shape-us-covid-19-policy/">
                                    advising the White House&rsquo;s COVID-19 policy
                                </a>
                                .
                            </p>
                        </div>
                        <div className="col-6">
                            <h3>
                                <a href="https://www.networkscienceinstitute.org/people/matteo-chinazzi">
                                    Matteo Chinazzi
                                </a>
                            </h3>
                            <h4>
                                Senior Research Scientist, MOBS Lab,
                                Northeastern University
                            </h4>
                            <p>
                                <span className="image left">
                                    <img src={chinazzi} alt="Sam Fraiberger" />
                                </span>
                                Matteo Chinazzi is a Senior Research Scientist
                                at the Laboratory for the Modeling of Biological
                                and Socio-Technical Systems (MOBS Lab) and
                                Part-time Faculty at Northeastern University.
                                His research interests include: the development
                                of computational and analytical models to study
                                the spatial spread of infectious diseases and
                                the development of individual-based models to
                                create realistic representations of human
                                contact patterns. Dr. Chinazzi is one of the
                                lead developers of the
                                {' '}
                                <a href="https://www.gleamproject.org/">
                                    Global Epidemic and Mobility model
                                </a>
                                , and the coordinator of the COVID-19 Mobility
                                project at the
                                {' '}
                                <a href="https://www.networkscienceinstitute.org/">
                                    Network Science Institute
                                </a>
                                . He is also a member of the council of the
                                Complex Systems Society (CSS), and he is one of
                                the co-founders of the association of Young
                                Researchers of the Complex Systems (yrCSS,
                                former YRNCS).
                            </p>
                        </div>
                    </div>
                </section>

                <section id="one" className={indexStyles.logolist}>
                    <header>
                        <h1 className="align-center">Industry Collaborators</h1>
                    </header>
                    <div className="grid-wrapper">
                        <div className="col-6">
                            <h3>Mark Birkhead</h3>
                            <h4>
                                Managing Director and Head of Decision Sciences,
                                JPMorgan Chase
                            </h4>
                            <p>
                                <span className="image left">
                                    <img src={Birkhead} alt="Mark Birkhead" />
                                </span>
                                Mark Birkhead is a Managing Director at JPMorgan
                                Chase and the Head of Decision Sciences for
                                Consumer Banking and Wealth Management. In this
                                capacity, Mark is responsible for utilizing
                                analytics, machine learning and artificial
                                intelligence to grow our businesses, enhance
                                sales, marketing and distribution, and to
                                improve the customer experience across the
                                Consumer Bank, US Wealth Management, US Private
                                Bank and International Private Bank. Mark brings
                                a strong record of building and transforming
                                analytic organizations both in the US and
                                globally. Most recently, Mark was the Chief
                                Analytic Officer at Santander US, responsible
                                building the data and analytics organizations
                                across five businesses, including the Retail
                                Bank. Mark also spent 10 years at Citigroup in
                                the Global Consumer Bank and Retail Services,
                                most recently as the Global Head of Analytics &
                                Insights for nine countries across Latin America
                                and Mexico. Prior to Citi, Mark worked at GE
                                Capital and Wells Fargo & Company.
                            </p>
                        </div>

                        <div className="col-6">
                            <h3>Ricardo Martin Manjón</h3>
                            <h4>Global Head of Data, BBVA</h4>
                            <p>
                                <span className="image left">
                                    <img src={ricardo} alt="Mark Birkhead" />
                                </span>
                                In April 2019, Ricardo Martin Manjón was
                                appointed Global Head of Data at BBVA. Ricardo
                                is responsible of leading the transformation of
                                the Group towards a data-driven company.
                                Previously, Ricardo was Head of Data Strategy
                                &amp; Data Science Innovation, spearheading the
                                most advanced applications of data and
                                artificial intelligence to the business at BBVA.
                                Prior to that, Ricardo has been Head of Digital
                                Banking at Nordea, the leading bank in the
                                Nordics. In this position, he was responsible
                                for defining and executing the overall digital
                                strategy for the Retail and Corporate segments
                                globally. Before that, Ricardo has been Head of
                                Digital Transformation at BBVA Spain for almost
                                a decade. This role included the overall digital
                                and physical distribution strategy and has been
                                key in the evolution of the bank to become a
                                leader in the digital space. Additionally,
                                Ricardo has also been Head of Marketing for BBVA
                                Spain for two years. Before joining BBVA in
                                2003, Ricardo had a fintech startup company that
                                was acquired by BBVA. He has a PhD in
                                Quantitative Economics from Universidad
                                Complutense de Madrid and an Executive MBA from
                                Columbia University and London Business School.
                            </p>
                        </div>

                        <div className="col-6">
                            <h3>Toby Scammell</h3>
                            <h4>Founder & CEO, Womply</h4>
                            <p>
                                <span className="image left">
                                    <img
                                        src={tobyScammell}
                                        alt="Toby Scammell"
                                    />
                                </span>
                                Toby Scammell founded Womply in 2011 after
                                seeing first-hand how underserved America&rsquo;s
                                small businesses were by technology. Today, he
                                leads the strategic vision for Womply&rsquo;s products
                                and works closely with members of Womply&rsquo;s
                                executive team, with a particular focus on the
                                intersection of product development and
                                go-to-market activities. Under Toby&rsquo;s
                                leadership, Womply has acquired more than
                                450,000 small business customers, become the
                                leading software partner to America&rsquo;s credit
                                card processing industry, raised $50 million in
                                growth funding, and expanded to hundreds of
                                employees across offices in San Francisco and
                                Lehi, Utah. Toby is a serial entrepreneur with a
                                strong business background. Prior to founding
                                Womply, he co-founded Feastery, where he spent
                                over a year solving customer acquisition and
                                loyalty problems for restaurants in San
                                Francisco. Before that, he was an associate
                                consultant at Bain & Company, one of the world&rsquo;s
                                top management consulting firms.
                            </p>
                        </div>

                        <div className="col-6">
                            <h3>Derek J. White</h3>
                            <h4>Chief Digital Officer, U.S. Bancorp</h4>
                            <p>
                                <span className="image left">
                                    <img src={derekWhite} alt="Derek White" />
                                </span>
                                Derek White is chief digital officer of U.S.
                                Bancorp, a financial services holding company
                                with businesses across the United States, Canada
                                and Europe. U.S. Bancorp is headquartered in
                                Minneapolis and is the parent company of U.S.
                                Bank, which is the fifth-largest commercial bank
                                in the United States. U.S. Bancorp is also the
                                parent company of Elavon, a leader in the
                                payment processing industry. White has served in
                                this position since joining U.S. Bancorp in June
                                2019. Prior to U.S. Bancorp, White was global
                                head of client solutions with BBVA in Spain. In
                                that role, he led a team of nearly 10,000 people
                                focused on concepting, designing, creating and
                                launching digital and innovation work – from
                                products and services to data science units and
                                venture creation. Prior to BBVA, White spent 12
                                years with Barclays in several leadership roles
                                including chief design & digital officer. White
                                earned his MBA from the University of
                                Pennsylvania – The Wharton School and a
                                bachelor&rsquo;s degree from Utah State University.
                            </p>
                        </div>

                        <div className="col-6">
                            <h3>Colleen Berube</h3>
                            <h4>Chief Information Officer, Zendesk</h4>
                            <p>
                                <span className="image left">
                                    <img src={colleen} alt="Derek White" />
                                </span>
                                As Zendesk&rsquo;s chief information officer, Colleen
                                is reshaping Zendesk&rsquo;s benchmark for modern IT
                                at scale and a customer-centric workforce. She
                                is passionate about the role of both customer
                                and employee experience in the digital age. With
                                more than 20 years of experience across multiple
                                industries and an ever-changing technology
                                landscape, Colleen is a digital- and
                                business-model transformation leader. Her
                                innovative strategies help companies put their
                                customers at the center and create work
                                environments focused on collaboration and open
                                communication. Previously, she was an executive
                                in residence with PwC where she worked on a
                                range of commercial and internal initiatives.
                                Prior to this, Colleen was vice president of IT
                                for Adobe Systems, and also held executive-level
                                IT roles at Cisco Systems, Palm, and SAP. In her
                                spare time, Colleen is an avid rower. She takes
                                what she learns about discipline and teamwork on
                                the water to the business world. She holds a
                                bachelor&rsquo;s degree from State University of New
                                York at Potsdam and an MBA from the University
                                of North Florida.
                            </p>
                        </div>

                        <div className="col-6">
                            <h3>Dennis Crowley</h3>
                            <h4>
                                Co-founder and Executive Chairman, Foursquare
                            </h4>
                            <p>
                                <span className="image left">
                                    <img src={crowley} alt="Dennis Crowley" />
                                </span>
                                Dennis Crowley is the co-founder and Executive
                                Chairman of
                                {' '}
                                <a href="http://foursquare.com/">Foursquare</a>
                                ,
                                the technology platform that powers location
                                experiences for more than 1 billion people
                                around the world. Previously, he founded
                                {' '}
                                <a href="https://medium.com/rick-webb/from-the-archives-soliloquy-for-dodgeball-ed8121dee285">
                                    Dodgeball
                                </a>
                                , one of the first location-based mobile social
                                services (acquired by Google in 2005) and help
                                to build early location-based games
                                {' '}
                                <a href="http://pacmanhattan.com/">
                                    PacManhattan
                                </a>
                                {' '}
                                (2004) and
                                {' '}
                                <a href="https://vimeo.com/131723074">
                                    ConQwest
                                </a>
                                {' '}
                                (2004). Dennis is also the founder and Chairman
                                of the
                                {' '}
                                <a href="http://stockadefc.com/">
                                    Kingston Stockade Football Club
                                </a>
                                , a semi-professional soccer team out of Hudson
                                Valley, NY that competes in the 4th division of
                                the US Soccer Pyramid. He has been named one of
                                Fortune&rsquo;s &ldquo;40 Under 40&rdquo; (
                                <a href="http://money.cnn.com/galleries/2010/fortune/1010/gallery.40_under_40.fortune/29.html">
                                    2010
                                </a>
                                ,
                                {' '}
                                <a href="http://archive.fortune.com/galleries/2011/news/companies/1110/gallery.40_under_40.fortune/28.html">
                                    2011
                                </a>
                                ), a member of Vanity Fair&rsquo;s &ldquo;New Establishment&rdquo;
                                (
                                <a href="http://www.vanityfair.com/online/daily/2011/09/the-2011-new-establishment-list--and-the-top-spot-goes-to---">
                                    2011
                                </a>
                                ,
                                {' '}
                                <a href="http://www.vanityfair.com/news/2012/09/49-dennis-crowley">
                                    2012
                                </a>
                                ) and has won the &ldquo;
                                <a href="http://www.flickr.com/photos/dpstyles/3331412360/">
                                    Fast Money
                                </a>
                                &rdquo; bonus round on the TV game show Family Feud (
                                <a href="http://www.flickr.com/photos/dpstyles/3331412360/">
                                    2009
                                </a>
                                ). He is currently an Adjunct Professor at NYU&rsquo;s
                                Interactive Telecommunications Program (
                                <a href="http://itp.nyu.edu/itp/">ITP</a>
                                ). Dennis holds a Master&rsquo;s degree from New York
                                University&rsquo;s Interactive Telecommunications
                                Program and a Bachelor&rsquo;s degree from the
                                Newhouse School at Syracuse University. He
                                splits his time between New York City and the
                                Hudson Valley with his wife Chelsa and their two
                                children, Via and Mars.
                            </p>
                        </div>
                    </div>
                </section>

                <section id="two" className={indexStyles.two}>
                    <div className="inner">
                        <p className="align-center">
                            <a href="mailto:c19-observatory@mit.edu">
                                email: c19-observatory@mit.edu
                            </a>
                        </p>
                        <p className="align-center">
                            <a href="https://twitter.com/C19Observatory">
                                twitter: @C19Observatory
                            </a>
                        </p>
                        <p className="align-center">
                            <a href="https://www.linkedin.com/company/c19observatory">
                                linkedIn: C19observatory
                            </a>
                        </p>
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export default HomeIndex
